import { notificationService } from '@services/notificationService';
import { getDisplayTimeLeft } from '@utils/common';
import React, { memo, useEffect, useRef } from 'react';

const NotiItem = memo((props) => {
   const { uid, read, notification, created_at, isFocused, resetFocused } = props;
   const animatedRef = useRef(null);

   useEffect(() => {
      if (isFocused && animatedRef.current) {
         animatedRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });

         setTimeout(() => {
            animatedRef.current.classList.add('animate__pulse');
            setTimeout(() => {
               animatedRef.current.classList.remove('animate__pulse');
               resetFocused();
            }, 500);
         }, 500);
      }
   }, [isFocused, resetFocused]);

   const markAsRead = async () => {
      const response = await notificationService.readNotification([uid]);
      if (response.isSuccess) {
         notificationService.fetchUserNotificationsAmount();
      }
   };

   return (
      <>
         {!read && <div className="profile-notifications-item__read" />}
         <div className="profile-notifications-item__container " data-noti-item={uid}>
            <div className="profile-notifications-item__header">
               <div
                  className="profile-notifications-item__header--title animate__animated"
                  ref={animatedRef}
               >
                  {notification.title}
               </div>
               <div className="profile-notifications-item__header--actions">
                  {!read && (
                     <button className="mark-as-read" onClick={markAsRead}>
                        Mark as Read
                     </button>
                  )}
               </div>
            </div>
            <div className="profile-notifications-item__time">
               {getDisplayTimeLeft(created_at)}
            </div>
            <div className="profile-notifications-item__content">
               <p>{notification.message}</p>
            </div>
         </div>
      </>
   );
});

export default NotiItem;
