import RequestModel from '@apis/models/RequestModel';
import Pagination from '@components/Pagination';
import SEO from '@components/SEO';
import { notificationService } from '@services/notificationService';
import constant from '@utils/constant';
import useHasChanged from '@utils/hooks/useHasChanged';
import {
   getNotificationsSelector,
   getPopupNotificationsCountSelector,
} from '@utils/selectors';
import NotiItem from '@views/profile/Notifications/NotiItem';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

const ProfileNotifications = memo((props) => {
   const { data, loading } = useSelector(getNotificationsSelector);
   const amount = useSelector(getPopupNotificationsCountSelector);
   const canceler = useRef(null);
   const [filter, setFilter] = React.useState({
      page: 1,
      pageSize: constant.defaultPagesize,
   });
   const { unread = 0 } = amount || {};

   const [focusedItem, setFocusedItem] = React.useState(null);

   const hasFilterChanged = useHasChanged(filter);
   const hasUnreadChanged = useHasChanged(unread);

   const {
      content = [],
      currentPage = 1,
      totalPages = 1,
      pageSize = constant.defaultPagesize,
      totalRows = 0,
   } = data || {};

   console.log(data);

   const loadData = async (filter) => {
      if (canceler.current) {
         canceler.current.cancel();
      }
      const requestBody = new RequestModel([filter]);
      canceler.current = await notificationService.fetchUserNotifications(requestBody);
      if (localStorage['noti-item-focused']) {
         setFocusedItem(localStorage['noti-item-focused']);
         localStorage.removeItem('noti-item-focused');
      }
   };

   useEffect(() => {
      if (hasFilterChanged || hasUnreadChanged) {
         loadData(filter);
      }
   }, [hasFilterChanged, hasUnreadChanged, filter]);

   useEffect(() => {
      if (currentPage && pageSize) {
         setFilter({
            page: currentPage,
            pageSize: pageSize,
         });
      }
   }, [currentPage, pageSize]);

   useEffect(() => {
      return () => {
         if (canceler.current) {
            canceler.current.cancel();
         }
      };
   }, []);

   const onPaginationChange = useCallback((page, pageSize) => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      });
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: page || prevFilter.page,
         pageSize: pageSize || prevFilter.pageSize,
      }));
   }, []);

   const markAllAsRead = async () => {
      const response = await notificationService.readAllNotification();
      if (response.isSuccess) {
         notificationService.fetchUserNotificationsAmount();
      }
   };

   const resetFocused = useCallback(() => {
      setFocusedItem(null);
   }, []);

   return (
      <>
         <SEO title={'Notifications'} />
         <div className="profile-card">
            <div className="profile-card__header">
               <div className="profile-card__header--tabs">
                  <div className="tab-item active">Notifications</div>
               </div>
               <div className="profile-card__header--actions">
                  <button
                     className="profile-notifications__mark-read-all"
                     disabled={unread === 0}
                     onClick={markAllAsRead}
                  >
                     Mark all as Read
                  </button>
               </div>
            </div>
            <div className="profile-card__body">
               <div className="profile-card__content">
                  <div className="profile-notifications">
                     {content?.length === 0 && !loading && (
                        <div className="profile-notifications__empty">
                           <span>You have no notifications</span>
                        </div>
                     )}
                     {content.map((item) => {
                        return (
                           <div
                              className="profile-card__item profile-notifications-item"
                              key={item.uid}
                           >
                              <NotiItem
                                 key={item.uid}
                                 {...item}
                                 isFocused={focusedItem === item.uid}
                                 resetFocused={resetFocused}
                              />
                           </div>
                        );
                     })}
                     {totalPages > 1 && (
                        <div className="profile-card__item profile-notifications__pagination">
                           <div className="common-table__pagination">
                              <div className="pagination-content">
                                 <Pagination
                                    page={currentPage}
                                    pageSize={pageSize}
                                    total={totalRows}
                                    onChange={onPaginationChange}
                                 />
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </>
   );
});

export default ProfileNotifications;
